
import { defineComponent, ref, onMounted, computed } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import addDistributionChannelsModal from "@/views/distribution-network/distribution-channels/AddChannelModal.vue";
import FilterDropdwon from "@/views/distribution-network/distribution-channels/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiChanel } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { formatDate, setModuleBCN } from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface Channel {
  id: number;
  name: string;
  status: number;
  parent_id: number;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string | Array<string>;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "channel",
  components: {
    MBDatatable,
    FilterDropdwon,
    addDistributionChannelsModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<Channel>>([]);
    const checkedDistributionChannelss = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExport = ref(false);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([
      { field: "status", value: ["10", "25"], condition: "in" },
    ]);
    const tableHeader = ref([
      {
        name: t("channel.channelId"),
        key: "id",
        sortable: true,
      },
      {
        name: t("channel.channel"),
        key: "name",
        sortable: true,
      },
      {
        name: t("channel.type"),
        key: "type",
        sortable: false,
      },
      {
        name: t("channel.parentChannel"),
        key: "parent_id",
        sortable: true,
      },
      {
        name: t("channel.systemStatus"),
        key: "status",
        sortable: true,
      },
      {
        name: "", // t("common.actions")
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getDistributionChannelsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const getDistributionChannelsList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiChanel.getChanelList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
            getDataSource();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const options = ref({
      parent_id: [] as any[],
    });
    const getDataSource = () => {
      ApiChanel.getDataSource({ status: 10 })
        .then(({ data }) => {
          options.value.parent_id = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "") {
          if (item == "name") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(1, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getDistributionChannelsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getDistributionChannelsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(1, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getDistributionChannelsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getDistributionChannelsList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getDistributionChannelsList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getDistributionChannelsList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const updateList = () => {
      getDistributionChannelsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const typeVal = computed(() => {
      return (val) => {
        let obj = options.value.parent_id.find((item) => {
          return item.value == val;
        });
        return obj?.label || val;
      };
    });

    // onBeforeMount(() => {
    //   getDataSource();
    // });

    return {
      t,
      formatDate,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledExport,
      checkedDistributionChannelss,
      getDistributionChannelsList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      options,
      typeVal,
      updateList,
    };
  },
});
