
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  name: string;
  id: string;
  parent_id: string;
  status: string;
}

export default defineComponent({
  name: "channel-dropdown",
  props: {
    optionsParentId: { type: Array, required: true },
  },
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const data = ref<Filter>({
      name: "",
      id: "",
      parent_id: "",
      status: "",
    });

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      // getCountryOptions();
    });
    return {
      t,
      data,
      formRef,
      submit,
      handleReset,
    };
  },
});
